










































































































































































































































































































































import Vue from "vue";
import { mapState } from "vuex";
import moment from "moment";
import { Toast, Swipe } from "vant";
import { dispatch, Product, Media, Sku, Attr } from "@/store";
import { deepCopy, share } from "@/utils";

export default Vue.extend({
  props: {
    hidden: Boolean,
  },
  data() {
    return {
      loading: true,
      product: {
        product: {
          main_medias: [] as Media[],
          detail_medias: [] as Media[],
        },
        skus: [] as Sku[],
        attrs: [] as Attr[],
      } as Product,
      bannerIndex: 0,
      activeTab: "detail",
      text: "",
      replyTo: {} as any,
      isReply: false,
      skuVisible: false,
      skuType: "buy",
      skuNum: 0,
      preview: "",
      showBuyerPhone: false,
      buyerPhone: "",
      discussionList: [],
      photoList: [],
      skuSelected: {
        amount: 0,
        coin: 0,
      } as Sku,
    };
  },
  computed: {
    ...mapState({ profile: "profile" }),
    ...mapState("cart", {
      cartQuery: "query",
    }),
    rate(): number {
      const follow_count = this.product.follow_count;
      return follow_count;
    },
    request(): string {
      const count = this.product.follow_count;
      if (!count) {
        return "";
      } else if (count < 10000) {
        return `${count}人想要`;
      } else {
        return `${(count / 1000).toFixed(1)}k人想要`;
      }
    },
    deliverAt(): string {
      const deliver_at = this.product.product.deliver_at;
      if (deliver_at) {
        return `预计发货时间：${moment(deliver_at).format(
          "YYYY年MM月DD日 HH:mm:ss"
        )}`;
      } else if (this.product.product.stage === "pre_sell") {
        return "开售发车，将于预定满员后发货";
      }
      return "";
    },
    textPlaceholder(): string {
      return "";
      // return this.isReply ? `回复 ${this.replyTo.nickname}` : "说点什么…";
    },
    isCoinPay(): boolean {
      return this.product.product.is_coin_pay;
    },
  },
  methods: {
    getProduct() {
      const { id, shareId } = this.$route.params;
      if (id) {
        if (shareId) {
          const productsShare = JSON.parse(
            localStorage.getItem("products-share") || "{}"
          );
          productsShare[id] = shareId;
          localStorage.setItem("products-share", JSON.stringify(productsShare));
        }
        const success = (product: Product) => {
          this.product = product;
          this.loading = false;
          this.skuSelected = product.skus[0];
          this.setWeChatShare();
        };
        const error = (e: Error) => {
          Toast("商品不存在或已下架");
          console.error(e);
          // setTimeout(() => {
          //   this.$router.replace(this.$paths.home);
          // }, 2000);
        };
        if (this.hidden) {
          dispatch.productsGetHidden(id, shareId).then(success).catch(error);
        } else {
          dispatch.productsGet(id, shareId).then(success).catch(error);
        }
      }
    },
    isAttrActive(name: string, value: string) {
      return this.skuSelected.options.find((option) => {
        return option.name === name && option.value === value;
      });
    },
    getDiscussions() {
      // this.$store.dispatch("discussions/getProductDiscussions");
    },
    getPhotos() {
      // this.$store.dispatch("photos/getProductPhotos");
    },
    setWeChatShare() {
      const media: Media = this.product.product.main_medias.find(
        (item: Media) => item.type === "image"
      );
      share({
        title: this.product.product.name,
        desc: this.product.product.description.replace(/(<([^>]+)>)/gi, ""),
        link: window.location.href,
        imgUrl: media ? media.url : "https://m.angrymiao.com/logo-medium.png",
      });
    },
    onBannerChange(index: number) {
      this.bannerIndex = index;
    },
    onTabChange(key: string) {
      switch (key) {
        case "discussion":
          this.getDiscussions();
          break;
        case "photos":
          this.getPhotos();
          break;
      }
    },
    onReply(replyTo: any) {
      this.isReply = true;
      this.replyTo = replyTo;
      (this.$refs.text as HTMLInputElement).focus();
    },
    onTextInput() {
      const text = this.$refs.text as HTMLInputElement;
      text.style.height = "auto";
      text.style.height = text.scrollHeight + "px";
    },
    onTextBlur() {
      if (this.isReply && !this.text) {
        this.afterSend();
      }
    },
    onTextSend() {
      if (this.activeTab === "discussion") {
        if (this.isReply) {
          this.$store
            .dispatch("discussions/addComment", {
              discussionId: this.replyTo.discussionId,
              content: this.text,
              commentType: "DiscussionComment",
            })
            .then(() => {
              this.$store.dispatch("discussions/getDiscussionComments", {
                discussionId: this.replyTo.discussionId,
              });
              this.afterSend();
            });
        } else {
          this.$store
            .dispatch("discussions/addDiscussion", {
              productId: this.product.product.id,
              content: this.text,
            })
            .then(() => {
              this.getDiscussions();
              this.afterSend();
            });
        }
      } else if (this.activeTab === "photos") {
        if (this.isReply) {
          this.$store
            .dispatch("photos/addComment", {
              discussionId: this.replyTo.discussionId,
              content: this.text,
              commentType: "PhotoComment",
            })
            .then(() => {
              this.$store.dispatch("photos/getPhotoComments", {
                discussionId: this.replyTo.discussionId,
                commentType: "PhotoComment",
              });
              this.afterSend();
            });
        }
      }
    },
    afterSend() {
      this.text = "";
      this.replyTo = {};
      this.isReply = false;
      (this.$refs.text as HTMLInputElement).style.height = "0.34rem";
    },
    onShowSku(type: string) {
      this.skuVisible = true;
      this.skuNum = this.skuSelected.has_stock ? 1 : 0;
      this.skuType = type;
    },
    onSkuSelect(index: number, value: string) {
      let options: any[] = deepCopy(this.skuSelected.options);
      options[index].value = value;
      let sku = this.product.skus.find((sku) => {
        return JSON.stringify(sku.options) === JSON.stringify(options);
      });
      if (!sku && index === 0) {
        sku = this.product.skus.find((sku) => {
          return JSON.stringify(sku.options).includes(
            JSON.stringify(options[index])
          );
        });
      }
      if (sku) {
        this.skuSelected = sku;
        this.onSkuNumberChange(sku.has_stock ? 1 : 0);
      } else {
        return Toast("商品暂无此规格，请选择其他" + options[0].name);
      }
    },
    onSkuNumberChange(num: number) {
      num = num >= 0 ? num : 0;
      num = num > this.skuSelected.limit ? this.skuSelected.limit : num;
      this.skuNum = num;
    },
    onSkuSubmit() {
      // this.$router.push(`/place-order/${this.product.id}/`)
      if (!this.skuSelected.has_stock) {
        return Toast("商品已经卖光了");
      }
      if (this.skuSelected.is_overdue) {
        return Toast("商品已经过期了");
      }
      if (!this.skuNum) {
        return Toast("请选择购买数量");
      }
      dispatch
        .cartPost({
          number: this.skuNum,
          product_id: this.product.product.id,
          sku_id: this.skuSelected.id as number,
        })
        .then(() => {
          if (this.skuType === "buy") {
            this.$router.push({
              path: this.$paths.cart,
              query: {
                ...this.$route.query,
                skus: String(this.skuSelected.id),
              },
            });
          } else {
            Toast("加购成功");
            dispatch.cartGetList(this.cartQuery);
            this.skuVisible = false;
          }
        });
    },
    onRequest() {
      if (!this.product.following && !this.profile.phone) {
        this.showBuyerPhone = true;
      } else {
        dispatch.productsFollow(this.product).then(() => {
          this.getProduct();
          if (this.product.following) {
            Toast("已取消想要");
          } else {
            Toast("已想要该商品");
          }
        });
      }
    },
    onRequestSubmit(action: string, done: (boolea?: boolean) => any) {
      const { buyerPhone } = this;
      if (!/^1[3456789]\d{9}$/.test(buyerPhone)) {
        Toast("请填写正确的手机号码");
        done(false);
        return;
      } else {
        dispatch
          .profilePatch({
            ...this.profile,
            phone: buyerPhone,
          })
          .then(() => {
            this.onRequest();
          });
        done();
      }
    },
    onCrowdfund() {
      window.location.href = this.product.product.url;
    },
    onFlipperClick(offset: number) {
      (this.$refs.banner as Swipe).swipeTo(this.bannerIndex + offset);
    },
  },
  created() {
    this.getProduct();
  },
  beforeDestroy() {
    share({
      title: "Angry Miao",
      desc: "在 Angry Miao 发掘更多新奇",
      link: window.location.href,
      imgUrl: "https://m.angrymiao.com/logo-medium.png",
    });
  },
});
